import React, { Component } from 'react';

const UVI = ({index}) => {
    if (index <=2) {
        return <span className="badge badge-success">{index}</span>
    }
    if (index <=5) {
        return <span className='badge badge-warning'>{index}</span>
    }
    if (index <=7) {
        return <span className='badge badge-danger'>{index}</span>
    }
    return <span className='badge badge-danger'><span role="img" aria-label="Warning">⚠️</span> {index}<span role="img" aria-label="Warning">⚠️</span></span>
};

class Weather extends Component {
    state = {
        weather: null
    }
    componentDidMount() {
        fetch('https://bolotov.kizill.ru/api/weather')
        .then(res => res.json())
        .then((data) => {
            this.setState({ weather: data })
        })
        .catch(console.log)
    }
    render() {
        if (this.state.weather) {
            let weather = this.state.weather;
            return (
                <div>
                <h3>Погодная информация</h3>
                {
                    <div className="card">

                    <div className="card-body">
                        <p className="card-text">
                        <span role="img" aria-label="Thermometer">🌡️</span> Температура: {weather.temperature > 0 ? '+': ''}{weather.temperature}
                        <br/>
                        <span role="img" aria-label="Drop">💧</span> Влажность: {weather.humidity}%
                        <br/>
                        <span role="img" aria-label="Sun">🌞</span> УФ-индекс: <UVI index={weather.uv_index}/>
                        <br/>
                        <span role="img" aria-label="Bars">📊</span> Давление: {weather.pressure} гПа
                        <br/>
                        <span role="img" aria-label="Wind">🌬️</span> Ветер: скорость {weather.wind} км/ч, порывами до {weather.wind_gust} км/ч.
                        <br/>
                        Направление {weather.wind_direction}
                        </p>
                    </div>
                    </div>
                }
                </div>
            )
        } else {
            return (
                <h3>Гружу погоду</h3>
            )
        }
    }
};

export default Weather