import React, { Component } from 'react';
import Weather from './Weather';
import ImageMapper from "react-img-mapper";
import ReactMarkdown from 'react-markdown';

var MAP = {
	name: "my-map",
	areas: [
		{
		    name: "Маша и Стас",
			markdown: "**Simplex house** [url](https://vk.com/kizill)",
            img: "simplex_house.JPG",
			shape: "poly",
			coords: [500,488, 566, 547,526 , 586,464, 528],
			lineWidth: 2,
			preFillColor: "rgba(255, 255, 255, 0.3)",
			strokeColor: "#6afd09"
		}
	]
};

var URL = "https://static.tildacdn.com/tild3135-6331-4332-a334-363734663836/map_20_10_2000_tiny.jpg";

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        area: null,
        text: "Кликни на участок чтоб начать"
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onClick(area) {
      this.setState({
          area: area,
          text: null
      })
  }

  clickedOutside(evt) {
      const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		this.setState({
			text: `You clicked on the image at coords ${JSON.stringify(coords)} !`,
            area: null
		});
  }

  render() {
    return (
      <div className="container">
      <div className="row">
          <div className="col-sm">
          <ImageMapper
                src={URL}
                map={MAP}
                width={700}
                imageWidth={750}

            //	onLoad={() => this.load()}
                onClick={area => {
                  this.onClick(area);
                  console.log(area);
                }}
                onImageClick={evt => this.clickedOutside(evt)}
                lineWidth={2}
                strokeColor={"white"}
            />
          </div>
            <div className="col-sm">
                {this.state.text &&
                <ReactMarkdown children={this.state.text}/>
                }
                {this.state.area &&
                    <div>
                    <h2>{this.state.area.name}</h2>
                    <ReactMarkdown children={this.state.area.markdown}/>
                    <img src={process.env.PUBLIC_URL + this.state.area.img} alt={this.state.area.name}/>
                    </div>
                }
          </div>
      </div>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <div>
      <Weather/>
      {/*<Clock/>*/}
      </div>
    );
  }
}

export default App;